import React, { ReactNode, useEffect, useState } from 'react';

import {
  Analytics,
  Book,
  Events,
  Integration,
  MeterAlt,
  Settings,
} from '@carbon/icons-react';
import {
  HeaderMenuItem,
  HeaderSideNavItems,
  SideNavItems,
  SideNavLink,
  SideNavMenu,
  SkeletonText,
  SwitcherDivider,
} from '@carbon/react';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useIntl } from 'react-intl';
import { Link, useLocation } from 'react-router-dom';
import { useTheme } from 'styled-components';

import { Box } from 'components/Containers';
import Translation from 'components/Content/Translation/Translation';
import { Guide, Phish, Respond } from 'components/CustomIcons';
import ThemeToggle from 'components/ThemeToggle/ThemeToggle';

import { MenuItemType } from '../Layout';

import {
  BottomSection,
  CustomTag,
  CybSafeLogo,
  PoweredByCybSafe,
  PoweredByText,
  StyledSideNav,
  TopMenu,
} from './SideNav.styles';

type TagTypeName =
  | 'red'
  | 'magenta'
  | 'purple'
  | 'blue'
  | 'cyan'
  | 'teal'
  | 'green'
  | 'gray'
  | 'cool-gray'
  | 'warm-gray'
  | 'high-contrast'
  | 'outline';

type TextWithTags = {
  title: ReactNode | string;
  tags: Array<{ name: string; color: TagTypeName }>;
  className?: string;
};

const MenuTextWithTags = ({ title, tags, className }: TextWithTags) => {
  const intl = useIntl();
  return (
    <span className={className}>
      {title}{' '}
      {tags.map((tag) => (
        <CustomTag title={tag.name} type={tag.color} key={tag.name}>
          {intl.formatMessage({ id: tag.name })}
        </CustomTag>
      ))}
    </span>
  );
};

function formatItems(menuItemsCopy, pathname) {
  let active = false;
  const formattedItems = menuItemsCopy.map((items) => {
    if (items.sub && items.sub.length > 0) {
      const { formattedItems: subFormatted, active: subActive } = formatItems(
        items.sub,
        pathname,
      );
      items.sub = subFormatted;
      items.expanded = subActive;
      if (subActive) active = true;
      return items;
    } else {
      items.active = pathname === items?.url;
      if (pathname === items?.url) active = true;
      return items;
    }
  });
  return { formattedItems, active };
}

export interface CybSafeSideNavProps {
  isSideNavExpanded: boolean;
  menuItems: MenuItemType[];
  menuBottomItems: MenuItemType[];
  headerItems: MenuItemType[];
  hasCustomLogo: boolean;
  withSideMenu: boolean;
  withTopMenu: boolean;
  direction: DirectionType;
  isAdmin: boolean;
  userGroup: number;
  updateFeatureMap?: (featureMap: Record<string, Array<string>>) => void;
  hasDelegatedAccess: boolean;
}

interface Tags {
  name: string;
  color: TagTypeName;
}

interface TopItem {
  label: string;
  icon: IconProp;
  displayAsTab: boolean;
  sideNavEntryPoint: boolean;
  itemPosition: string;
  isExternalLink?: boolean;
  url: string;
  features: Array<string>;
}

interface SubItem extends TopItem {
  sub?: SubItem[];
  tags?: Tags[];
  expanded: boolean;
  active: boolean;
  divider?: boolean;
}

const CUSTOM_MENU_ICONS = {
  guide: <Guide />,
  phish: <Phish />,
  respond: <Respond />,
};

const MenuItemIcon = ({ icon }) => {
  const isCustomIcon = Object.keys(CUSTOM_MENU_ICONS).includes(icon);
  if (isCustomIcon) {
    return CUSTOM_MENU_ICONS[icon];
  }
  // Temporary override to icons to use Carbon, this will be removed in the future when we move to menu structure from code.
  switch (icon) {
    case 'tachometer-alt':
      return <MeterAlt />;

    case 'chart-area':
      return <Analytics />;

    case 'users':
      return <Events />;

    case 'integrations':
      return <Integration />;

    case 'cog':
      return <Settings />;

    case 'book-reader':
      return <Book />;

    default:
      return <FontAwesomeIcon icon={icon} color="currentColor" />;
  }
};

const getUrlFeatures = (manuItem: SubItem[]) => {
  let urlFeaturesRecord: Record<string, Array<string>> = {};
  manuItem.forEach((item) => {
    if (item.sub && item.sub.length > 0) {
      const childrenUrlFeatures = getUrlFeatures(item.sub);
      urlFeaturesRecord = { ...urlFeaturesRecord, ...childrenUrlFeatures };
    } else {
      urlFeaturesRecord = {
        ...urlFeaturesRecord,
        [item.url]: item.features,
      };
    }
  });
  return urlFeaturesRecord;
};

export default function CybSafeSideNav({
  isSideNavExpanded,
  menuItems,
  menuBottomItems,
  headerItems,
  withSideMenu,
  withTopMenu,
  direction,
  hasCustomLogo,
  isAdmin,
  userGroup,
  updateFeatureMap,
  hasDelegatedAccess,
}: CybSafeSideNavProps) {
  const theme = useTheme();
  const intl = useIntl();
  const location = useLocation();
  const isConnectApp = localStorage.getItem('isConnectApp');

  const [sidebarTopItems, setSidebarTopItems] = useState<SubItem[]>([]);
  const [topItems, setTopItems] = useState<TopItem[]>([]);

  useEffect(() => {
    const menuItemsCopy = menuItems.slice();
    const headerItemsCopy = headerItems.slice();
    const { formattedItems: formattedSideTopItems } = formatItems(
      menuItemsCopy,
      location.pathname,
    );

    const { formattedItems: formattedHeaderItems } = formatItems(
      headerItemsCopy,
      location.pathname,
    );
    setSidebarTopItems(formattedSideTopItems);
    setTopItems(formattedHeaderItems);

    const sideTopUrlFeatureMap = getUrlFeatures(formattedSideTopItems);

    const headerUrlFeatureMap = getUrlFeatures(formattedHeaderItems);
    const urlFeaturesMap = { ...sideTopUrlFeatureMap, ...headerUrlFeatureMap };

    Object.values(urlFeaturesMap).length > 0 &&
      updateFeatureMap &&
      updateFeatureMap(urlFeaturesMap);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [menuItems, menuBottomItems, headerItems, location.pathname]);

  const submenuItems = (item: SubItem) =>
    item.sub
      .filter(
        (item) =>
          !item.displayAsTab || (item.displayAsTab && item.sideNavEntryPoint),
      )
      .map((subItem, index) => {
        if (
          subItem.sub &&
          subItem.sub.length > 0 &&
          !subItem.sideNavEntryPoint
        ) {
          return (
            <SideNavMenu
              title={intl.formatMessage({ id: subItem.label })}
              key={`${subItem.label}_${index}`}
              isActive={item.active || recursivelyCheckIfActive(subItem.sub)}
              defaultExpanded={subItem.expanded}
            >
              {submenuItems(subItem)}
            </SideNavMenu>
          );
        } else {
          return (
            <SideNavLink
              key={`${subItem.label}_${index}`}
              id={subItem.label}
              as={subItem.isExternalLink ? 'a' : Link}
              {...(!subItem.isExternalLink && {
                to: subItem.url,
              })}
              {...(subItem.isExternalLink && {
                href: subItem.url,
                target: '_blank',
              })}
              isActive={subItem.active || recursivelyCheckIfActive(subItem.sub)}
            >
              <>
                <Translation id={subItem.label}>
                  {(txt) => (
                    <>
                      <MenuTextWithTags
                        title={txt}
                        className="cds--side-nav__link-text"
                        tags={subItem.tags}
                      />
                    </>
                  )}
                </Translation>
                {subItem.isExternalLink && (
                  <FontAwesomeIcon icon={faExternalLinkAlt} />
                )}
              </>
            </SideNavLink>
          );
        }
      });

  const recursivelyCheckIfActive = (items: SubItem | SubItem[]) => {
    if (!Array.isArray(items)) {
      items = [items];
    }

    return items.some((item) => {
      if (item.active) {
        return true;
      }
      if (location.pathname.includes(item.url)) {
        return true;
      }
      if (item.sub) {
        return recursivelyCheckIfActive(item.sub);
      }
      return false;
    });
  };

  return (
    <StyledSideNav
      data-testid="side-menu"
      aria-label="Side navigation"
      expanded={isSideNavExpanded}
      $isAdmin={isAdmin || userGroup === 7}
      $withSideMenu={withSideMenu}
      $hasDelegatedAccess={hasDelegatedAccess}
    >
      <SideNavItems>
        {withTopMenu && topItems.length > 0 && (
          <HeaderSideNavItems hasDivider={true}>
            {topItems.map((item, index) => (
              <HeaderMenuItem
                key={index}
                id={`${item.label}-top`}
                target={item.isExternalLink ? '_blank' : undefined}
                as={item.isExternalLink ? 'a' : Link}
                {...(item.isExternalLink && { href: item.url })}
                {...(!item.isExternalLink && { to: item.url })}
              >
                <Translation id={item.label} />
              </HeaderMenuItem>
            ))}
          </HeaderSideNavItems>
        )}
        {!isConnectApp && sidebarTopItems.length === 0 && (
          <Box p="8px">
            <Box>
              <SkeletonText />
            </Box>
            <Box w="80%">
              <SkeletonText />
            </Box>
            <Box w="80%">
              <SkeletonText />
            </Box>
            <Box w="80%">
              <SkeletonText />
            </Box>
          </Box>
        )}
        {!isConnectApp &&
          sidebarTopItems.map((item, index) => {
            if (
              item.sub &&
              item.sub.length > 0 &&
              !item.sideNavEntryPoint &&
              !item.displayAsTab
            ) {
              return (
                <TopMenu
                  dir={direction}
                  key={index}
                  defaultExpanded={item.expanded}
                  renderIcon={
                    item.icon ? () => <MenuItemIcon icon={item.icon} /> : null
                  }
                  title={intl.formatMessage({ id: item.label })}
                >
                  {submenuItems(item)}
                </TopMenu>
              );
            } else if (item.divider) {
              return <SwitcherDivider key={index} />;
            } else {
              return (
                <SideNavLink
                  key={index}
                  id={item.label}
                  renderIcon={
                    item.icon ? () => <MenuItemIcon icon={item.icon} /> : null
                  }
                  as={item.isExternalLink ? 'a' : Link}
                  {...(item.isExternalLink && { href: item.url })}
                  {...(!item.isExternalLink && { to: item.url })}
                  target={item.isExternalLink ? '_blank' : null}
                  className={`cds--side-nav__link ${
                    item.active && 'cds--side-nav__link--current'
                  }`}
                >
                  <MenuTextWithTags
                    title={intl.formatMessage({ id: item.label })}
                    className="cds--side-nav__link-text"
                    tags={item.tags}
                  />
                  {item.isExternalLink && (
                    <FontAwesomeIcon
                      icon={faExternalLinkAlt}
                      color="currentColor"
                    />
                  )}
                </SideNavLink>
              );
            }
          })}
      </SideNavItems>
      <BottomSection>
        <ThemeToggle />
        {hasCustomLogo && (
          <PoweredByCybSafe>
            <PoweredByText>
              <Translation id="partner_poweredby" />
            </PoweredByText>
            <CybSafeLogo
              src={
                theme.name === 'light'
                  ? 'https://cybsafe-resources.s3.eu-west-1.amazonaws.com/brand/CYBSAFE-logo-black.svg'
                  : 'https://s3-eu-west-1.amazonaws.com/cybsafe-resources/cybsafe_logo_white-01.svg'
              }
              alt="CybSafe"
            />
          </PoweredByCybSafe>
        )}
      </BottomSection>
    </StyledSideNav>
  );
}
