import React from 'react';

const PhishIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
  >
    <path d="M5.30763 14.5166C5.26906 14.5166 5.23048 14.5156 5.19142 14.5117C4.61085 14.459 4.13623 14.0205 4.03761 13.4434L3.69922 11.4775L1.83351 10.7725C1.28859 10.5664 0.948255 10.0156 1.00636 9.43457C1.06495 8.85596 1.50782 8.38525 2.08302 8.28955L4.21094 7.94238C4.22949 7.57031 4.27051 7.20312 4.33253 6.84521C4.3335 6.82519 4.33594 6.80468 4.33984 6.78466C4.64013 5.14843 5.39112 3.79491 6.51173 2.8706C7.63282 1.94628 9.12111 1.46386 10.7676 1.48339C12.4316 1.50194 13.7197 2.00048 14.0752 2.15282C14.4561 2.31786 14.7363 2.6577 14.8272 3.06249C14.9092 3.4414 15.1543 4.79784 14.8555 6.43798C14.5576 8.0747 13.8076 9.42968 12.6856 10.3555C11.5645 11.2793 10.0899 11.7471 8.42872 11.7422C8.04688 11.7383 7.65771 11.708 7.2671 11.6523L6.52052 13.6719C6.33302 14.1816 5.84474 14.5166 5.30763 14.5166ZM5.33399 6.90918C5.33253 6.92773 5.33008 6.94629 5.32715 6.96436C5.24463 7.41895 5.20166 7.89258 5.19873 8.37207C5.19727 8.61621 5.02002 8.82324 4.7793 8.86279L2.24561 9.27636C2.11377 9.29784 2.01465 9.40234 2.00147 9.53515C1.98829 9.66601 2.06446 9.79101 2.18702 9.83691L4.32032 10.6435C4.48634 10.706 4.60645 10.8516 4.63623 11.0264L5.02296 13.2744C5.04542 13.4053 5.15138 13.5039 5.28126 13.5156C5.4131 13.5254 5.53712 13.4502 5.58253 13.3252L6.47267 10.918C6.55665 10.6904 6.79689 10.5557 7.03371 10.6006C7.50734 10.6894 7.97999 10.7373 8.43897 10.7422C9.45801 10.7402 10.8662 10.5596 12.0488 9.58398C13.2334 8.60644 13.6943 7.23632 13.8711 6.25927C14.1367 4.80126 13.9229 3.60839 13.8506 3.27782C13.8301 3.187 13.7656 3.10887 13.6787 3.07128C13.3691 2.93798 12.2354 2.49999 10.7568 2.48339C9.7998 2.48144 8.33204 2.6665 7.14844 3.64208C5.98731 4.5996 5.52051 5.93652 5.33399 6.90918Z" />
    <path d="M11.2246 6.13923C11.658 6.08376 11.9644 5.68746 11.9089 5.25407C11.8534 4.82068 11.4571 4.51432 11.0237 4.56979C10.5903 4.62526 10.284 5.02156 10.3395 5.45495C10.3949 5.88833 10.7912 6.1947 11.2246 6.13923Z" />
  </svg>
);

export default PhishIcon;
