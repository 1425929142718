import React from 'react';

import { Link } from 'react-router-dom';

import Translation from 'components/Content/Translation/Translation';

import {
  IconContainer,
  NumberValue,
  NumberValueContainer,
} from './NumberWithIcon.styles';

export type NumberWithIconProps = {
  /**
   * value: The value to display.
   */
  value: number | string;
  /**
   * Link: A link to redirect to another page when clicked.
   */
  link?: string;
  /**
   * icon: The Carbon icon to display.
   */
  renderIcon: React.ReactElement;
  /**
   * label: The label to display below the value and icon.
   */
  label?: string;
  /**
   * label: The accessible label for screen readers.
   */
  ariaLabel?: string;
};

const NumberWithIcon = ({
  value,
  renderIcon,
  label,
  link,
  ariaLabel,
}: NumberWithIconProps) => {
  const Icon = React.cloneElement(renderIcon as React.ReactElement, {
    size: 32,
  });
  return (
    <NumberValueContainer>
      <p className="cds--visually-hidden">{ariaLabel}</p>
      {/* Hide the below from screen readers because the aria-label above should be used as a description */}
      <NumberValue aria-hidden={true}>
        {link ? <Link to={link}>{value}</Link> : <span>{value}</span>}
        <IconContainer>{Icon}</IconContainer>
      </NumberValue>
      {label && (
        /* Hide the below from screen readers because the aria-label above should be used as a description */
        <span aria-hidden={true}>
          <Translation id={label} />
        </span>
      )}
    </NumberValueContainer>
  );
};

export default NumberWithIcon;
