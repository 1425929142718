import React from 'react';

import { Events } from '@carbon/icons-react';

import Translation from 'components/Content/Translation/Translation';
import { NumberWithIcon } from 'components/Reports';
import CybDataTable, { TableColumn } from 'components/Table/CybDataTable';
import { BatchActionsInterface } from 'components/Table/CybDataTable/BatchActions/BatchActions';
import { PeopleHeading } from 'pages/Settings/GroupManagement/components/GroupManagement.styles';

type DataRow = {
  id: number;
  email: string;
  name: string;
};

type PeopleTableProps = {
  data: DataRow[];
  columns: TableColumn<DataRow>[];
  loading: boolean;
  paginationTotalRows: number;
  placeholder: string;
  searchValue: string;
  onSearchSubmit: (value: string) => void;
  paginationPerPage: number;
  onPageChange: (page: number) => void;
  onRowSelect: (row: any) => void;
  batchActions: BatchActionsInterface[];
  successNotification?: {
    display: boolean;
    title: string;
    subtitle: string;
    onClose: () => void;
  };
  errorNotification?: {
    display: boolean;
    title: string;
    subtitle: string;
  };
  iconLabel: string;
  headingLabel: string;
  totalUsers: number;
};

const PeopleTable = ({
  data,
  columns,
  loading,
  paginationTotalRows,
  placeholder,
  searchValue,
  onSearchSubmit,
  paginationPerPage,
  onPageChange,
  onRowSelect,
  batchActions,
  iconLabel,
  headingLabel,
  totalUsers,
}: PeopleTableProps) => (
  <>
    <NumberWithIcon
      value={totalUsers}
      label={iconLabel}
      renderIcon={<Events />}
    />
    <PeopleHeading>
      <Translation id={headingLabel} />
    </PeopleHeading>

    <CybDataTable
      data={data}
      columns={columns}
      selectableRows
      loading={loading}
      paginationServer
      paginationTotalRows={paginationTotalRows}
      search={{
        placeholder: placeholder,
        value: searchValue,
        onSubmit: onSearchSubmit,
      }}
      paginationPerPage={paginationPerPage}
      onChangePage={onPageChange}
      onSelectedRowsChange={onRowSelect}
      batchActions={batchActions}
    />
  </>
);

export default PeopleTable;
