import React, { ReactNode, useMemo } from 'react';

import { useIntl } from 'react-intl';
import { useSearchParams } from 'react-router-dom';

import Translation from 'components/Content/Translation/Translation';
import { Tab, TabList, TabPanel, TabPanels, Tabs } from 'components/Tabs';
import { Wrapper } from 'components/Wrappers';

import ModulesTab from './ModulesTab';
import PeopleTab from './PeopleTab';
import SettingsTab from './SettingsTab';
import SubgroupsTab from './SubgroupsTab';

type TabType = {
  id: string;
  label: ReactNode;
  panel: ReactNode;
};

export const tabIDs = {
  people: 'people',
  'sub-groups': 'sub-groups',
  modules: 'modules',
  settings: 'settings',
};

const getTabs = (
  groupData,
  allGroups,
  setUpdateGroupOverview,
  isRoot,
  refetch,
) => {
  const tabConfig = {
    [tabIDs.people]: {
      id: tabIDs.people,
      label: <Translation id="label_people" />,
      panel: (
        <Wrapper>
          <PeopleTab
            groupName={groupData.displayName}
            managedByADSyncOrSSO={
              groupData.managedByAdSync || groupData.creationMethod === 'sso'
            }
            updateOverviewSection={() => setUpdateGroupOverview(true)}
          />
        </Wrapper>
      ),
    },
    [tabIDs['sub-groups']]: {
      id: tabIDs['sub-groups'],
      label: <Translation id="page.groupManagement.subgroups.header" />,
      panel: (
        <Wrapper>
          <SubgroupsTab />
        </Wrapper>
      ),
    },
    [tabIDs.modules]: {
      id: tabIDs.modules,
      label: <Translation id="label_contents" />,
      panel: (
        <Wrapper>
          <ModulesTab />
        </Wrapper>
      ),
    },
    [tabIDs.settings]: {
      id: tabIDs.settings,
      label: <Translation id="label_settings" />,
      panel: (
        <SettingsTab
          groupName={groupData.displayName}
          hasChildren={groupData.childrenCount !== 0}
          allGroups={allGroups}
          getGroupData={refetch}
          managedByADSync={groupData.managedByAdSync}
        />
      ),
    },
  };

  return Object.values(tabIDs)
    .filter(function filterPeopleTabIfRoot(tab) {
      return !(isRoot && tab === tabIDs.people);
    })
    .filter(function filterSettingsTabIfGroupTypeIsSSOorSync(tab) {
      return !(
        (groupData.creationMethod === 'sso' && tab === tabIDs.settings) ||
        (groupData.creationMethod === 'scim' && tab === tabIDs.settings)
      );
    })
    .map(function mapToTabConfig(tab) {
      return tabConfig[tab];
    });
};

const getDefaultSelectedTab = (tabs: TabType[], tabId?: string) => {
  if (tabId) {
    return tabs.findIndex((tab) => tab.id === tabId);
  }
  return 0;
};

const ManageGroupTabs = ({
  data,
  allGroups,
  setUpdateGroupOverview,
  isRoot,
  refetch,
}) => {
  const intl = useIntl();
  const [params] = useSearchParams();
  const tabId = params.get('selected');
  const tabs = useMemo(
    () => getTabs(data, allGroups, setUpdateGroupOverview, isRoot, refetch),
    [allGroups, data, isRoot, refetch, setUpdateGroupOverview],
  );
  return (
    <Tabs defaultSelectedIndex={getDefaultSelectedTab(tabs, tabId)}>
      <TabList
        aria-label={intl.formatMessage({
          id: 'component.tabs.tabList.ariaLabel',
        })}
      >
        {tabs.map((tab) => (
          <Tab key={tab.id}>{tab.label}</Tab>
        ))}
      </TabList>
      <TabPanels>
        {tabs.map((tab) => (
          <TabPanel key={tab.id}>{tab.panel}</TabPanel>
        ))}
      </TabPanels>
    </Tabs>
  );
};

export default ManageGroupTabs;
