import { HeaderName } from '@carbon/react';
import { LinkProps as RRLinkProps } from 'react-router-dom';
import styled from 'styled-components';

export const HEADER_HEIGHT_VALUE = 64;
export const HEADER_HEIGHT = `${HEADER_HEIGHT_VALUE}px`;
export const DELEGATED_ACCESS_HEADER_HEIGHT_VALUE = 50;
export const DELEGATED_ACCESS_HEADER_HEIGHT = `${DELEGATED_ACCESS_HEADER_HEIGHT_VALUE}px`;

export const Logo = styled.img`
  height: 47px;
  @media only screen and (max-width: 1024px) {
    height: 2.5rem;
  }
`;

export const LogoSeparator = styled.div`
  position: relative;
  margin: 0 20px;
  &:after {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    border-left: 1px solid ${({ theme }) => theme.colorPalette.grey[100]};
  }
  @media only screen and (max-width: 500px) {
    margin: 0;
  }
`;

export const LogoContainer = styled.div`
  display: flex;
  padding-right: 16px;

  @media only screen and (min-width: 1025px) {
    justify-content: flex-start;
  }
`;

export const StyledHeaderName = styled(HeaderName)<RRLinkProps>`
  &:focus {
    outline: 2px solid ${({ theme }) => theme.colorPalette.primary[300]};
    outline-offset: -2px;
  }
`;
