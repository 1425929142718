import React, { useCallback, useMemo, useState } from 'react';

import { useIntl } from 'react-intl';

import Translation from 'components/Content/Translation/Translation';
import { usePrevious } from 'utils/hooks';

import { ComboBoxProps } from '../../ComboBox';
import { GroupItem, Item } from '../../Dropdowns.types';
import { ComboBox, FilterableMultiSelect } from '../../index';
import { MultiSelectProps } from '../../MultiSelect';

import GroupsItem from './GroupsItem';
import useGroupsDropdownItems from './useGroupsDropdownItems';

type CommonGroupsDropdown = {
  /**
   * Gets the client name and adds it to the top of the dropdown list as a selectable item.
   */
  withClientAsRoot?: boolean;
};

type GroupsDropdownProps = CommonGroupsDropdown &
  Omit<
    ComboBoxProps,
    'items' | 'label' | 'selectedItem' | 'initialSelectedItem'
  > & {
    hideLabel?: boolean;
    selectedGroup?: number | string;
  };

const GroupsDropdown = ({
  withClientAsRoot,
  selectedGroup,
  ...rest
}: GroupsDropdownProps) => {
  const { hideLabel } = rest;
  const intl = useIntl();
  const [searchValue, setSearchValue] = useState(null);
  const prevSearchValue = usePrevious(searchValue);
  const { items, loading, error } = useGroupsDropdownItems(withClientAsRoot);

  const selectedItem: Item = useMemo(() => {
    if (selectedGroup) {
      const formattedSelectedGroup =
        selectedGroup === 'root' ? selectedGroup : parseInt(`${selectedGroup}`);
      return items.find((item) => item.id === formattedSelectedGroup);
    }
    return null;
  }, [items, selectedGroup]);

  const itemToElement = useCallback(
    (item: GroupItem) => {
      /* Display different element item when search is being used */
      return (
        <GroupsItem
          {...item}
          searchActive={!!searchValue && searchValue !== selectedItem?.label}
        />
      );
    },
    [searchValue, selectedItem?.label],
  );

  const onInputChange = useCallback(
    (inputValue) => {
      /* Only update the search value when it is different to the previous value */
      if (inputValue !== prevSearchValue) {
        setSearchValue(inputValue);
      }
    },
    [prevSearchValue],
  );

  if (error) return null;
  return (
    <ComboBox
      {...rest}
      items={items}
      loading={loading}
      titleText={!hideLabel && <Translation id="common.groups" />}
      aria-label={intl.formatMessage({ id: 'common.groups' })}
      placeholder={intl.formatMessage({
        id: 'component.field.select.defaultPlaceholder',
      })}
      initialSelectedItem={selectedItem}
      itemToElement={itemToElement}
      onInputChange={onInputChange}
    />
  );
};

type GroupsMultiSelectProps = CommonGroupsDropdown &
  Omit<
    MultiSelectProps,
    'items' | 'label' | 'selectedItems' | 'initialSelectedItems'
  > & {
    selectedGroups?: number[] | string[];
  };

const GroupsMultiSelect = ({
  withClientAsRoot,
  selectedGroups,
  ...rest
}: GroupsMultiSelectProps) => {
  const intl = useIntl();
  const [searchValue, setSearchValue] = useState(null);
  const prevSearchValue = usePrevious(searchValue);
  const { items, loading, error } = useGroupsDropdownItems(withClientAsRoot);

  const selectedItems = useMemo(() => {
    if (selectedGroups) {
      const formattedSelectedGroups = selectedGroups.map((g) =>
        g === 'root' ? g : parseInt(g),
      );
      return items.filter((item) => formattedSelectedGroups.includes(item.id));
    }
    return [];
  }, [items, selectedGroups]);

  const sortItems = useCallback((items: Item[]) => {
    /* Prevent Carbon from sorting alphabetically by default */
    return items;
  }, []);

  const itemToElement = useCallback(
    (item: GroupItem) => {
      /* Display different element item when search is being used */
      return (
        <GroupsItem {...item} key={item.id} searchActive={!!searchValue} />
      );
    },
    [searchValue],
  );

  const onInputValueChange = useCallback(
    (inputValue) => {
      /* Only update the search value when it is different to the previous value */
      if (inputValue !== prevSearchValue) {
        setSearchValue(inputValue);
      }
    },
    [prevSearchValue],
  );

  if (error) return null;
  return (
    // @ts-ignore
    <FilterableMultiSelect
      {...rest}
      items={items}
      loading={loading}
      titleText={<Translation id="common.groups" />}
      aria-label={intl.formatMessage({ id: 'common.groups' })}
      placeholder={intl.formatMessage({
        id: 'component.field.select.defaultPlaceholder',
      })}
      initialSelectedItems={selectedItems}
      sortItems={sortItems}
      itemToElement={itemToElement}
      onInputValueChange={onInputValueChange}
    />
  );
};

export { type GroupsDropdownProps, type GroupsMultiSelectProps };

export { GroupsDropdown, GroupsMultiSelect };
