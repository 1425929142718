import React from 'react';

const GuideIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M4.59298 10.5594C4.3879 11.0899 4.91016 11.6121 5.44063 11.4071L9.38634 9.88948C9.61876 9.79924 9.80196 9.61877 9.88946 9.38635L11.407 5.44065C11.6121 4.91018 11.0899 4.38791 10.5594 4.59299L6.61368 6.11057C6.38126 6.2008 6.20079 6.38127 6.11055 6.61369L4.59298 10.5594ZM7.02411 7.02413L5.80424 10.1958L8.9759 8.97592L10.1958 5.80425L7.02411 7.02413Z"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M8 14C11.3137 14 14 11.3137 14 8C14 4.68629 11.3137 2 8 2C4.68629 2 2 4.68629 2 8C2 11.3137 4.68629 14 8 14ZM8 15C11.866 15 15 11.866 15 8C15 4.13401 11.866 1 8 1C4.13401 1 1 4.13401 1 8C1 11.866 4.13401 15 8 15Z"
    />
    <circle cx="8.00468" cy="8.00468" r="0.5" fill="black" />
  </svg>
);

export default GuideIcon;
