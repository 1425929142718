import React, { useState } from 'react';

import { Form, Formik } from 'formik';
import { useIntl } from 'react-intl';
import { useNavigate, useParams } from 'react-router-dom';
import * as Yup from 'yup';

import { useApiFetcher, useApiPatch } from 'api';
import Button from 'components/Button';
import { FlexBox } from 'components/Containers';
import Translation from 'components/Content/Translation/Translation';
import { InputText } from 'components/Form/Inputs';
import { GroupsDropdown } from 'components/Form/Inputs/Dropdowns';
import Label from 'components/Form/Inputs/Label/Label';
import { InlineNotification } from 'components/Notifications';
import { Wrapper } from 'components/Wrappers';
import { unit } from 'theme';

import { ButtonContainer, DropdownContainer } from './GroupManagement.styles';

interface SettingsTabProps {
  groupName: string;
  allGroups: any;
  hasChildren: boolean;
  getGroupData: () => void;
  managedByADSync?: boolean;
  allGroupsError?: boolean;
}

interface NotificationState {
  success: boolean;
  error: boolean;
}
interface GroupData {
  id: number | string;
  displayName: string;
  parentId: number | null;
  children: GroupData[];
}

interface IParamsToSend {
  displayName?: string;
  parent?: null | string;
}

const findParent = (groups: Array<GroupData>, groupId: string) => {
  const currentGroupId = parseInt(groupId);
  let parent: any = null;

  const loopItems = (items: Array<GroupData>) => {
    items.forEach((item) => {
      const parentItem = item.children?.find(
        (child) => child.id === currentGroupId,
      );
      if (parentItem) {
        parent = item.id;
      } else if (item.children?.length > 0) {
        loopItems(item.children);
      }
    });
  }
  loopItems(groups);
  return parent;
};

const SettingsTab = ({
  groupName,
  allGroups,
  getGroupData,
  managedByADSync,
}: SettingsTabProps) => {
  const intl = useIntl();
  const navigate = useNavigate();
  const { groupId } = useParams<'groupId'>();
  const initialValues = {
    belongsTo: findParent(allGroups || [], groupId),
    groupName: groupName || '',
  };
  const [deleteCategory, { error: deleteCategoryError }] = useApiFetcher();
  const [updateCategory] = useApiPatch(`/api/v1/categories/r1/${groupId}`);

  const handleDeleteGroup = async () => {
    if (
      window.confirm(intl.formatMessage({ id: 'label_confirmDeleteGroup' }))
    ) {
      await deleteCategory('DELETE', `/api/v1/categories/r1/${groupId}`);
      navigate('/settings/group-management');
    }
  };

  const [notificationState, setNotificationState] = useState<NotificationState>(
    {
      success: false,
      error: false,
    },
  );

  return (
    <Wrapper id="settings-section">
      {(notificationState.success || notificationState.error) && (
        <InlineNotification
          type={notificationState.success ? 'success' : 'error'}
          titleLabel={intl.formatMessage({
            id: notificationState.success
              ? 'label_parentSuccessTitle'
              : 'label_parentErrorTitle',
          })}
          subtitleLabel={intl.formatMessage({
            id: notificationState.success
              ? 'label_parentSuccessSubtitle'
              : 'label_parentErrorSubtitle',
          })}
        />
      )}
      {managedByADSync && (
        <InlineNotification
          type="info"
          titleLabel={intl.formatMessage({
            id: 'label_peopleManagedByADSyncHeading',
          })}
          subtitleLabel={intl.formatMessage({
            id: 'label_peopleManagedByADSyncSubHeading',
          })}
        />
      )}
      <Formik
        initialValues={initialValues}
        onSubmit={async (values, actions) => {
          const paramsToSend: IParamsToSend = {};
          if (values.groupName !== initialValues.groupName) {
            paramsToSend.displayName = values.groupName;
          }
          if (values.belongsTo !== initialValues.belongsTo) {
            paramsToSend.parent =
              values.belongsTo === 'root' ? null : values.belongsTo;
          }
          try {
            await updateCategory({ ...paramsToSend });
            await getGroupData();
            setNotificationState({ error: false, success: true });
          } catch (error) {
            setNotificationState({ success: false, error: true });
          }
          actions.setSubmitting(false);
        }}
        validationSchema={Yup.object().shape({
          groupName: Yup.string().required('common.formError.fieldRequired'),
        })}
        validateOnChange={false}
        validateOnBlur={false}
      >
        {({ errors, setFieldValue, values, isSubmitting, dirty }) => (
          <Form>
            <FlexBox flexDirection="column" gap={unit(2)}>
              <Label label="label_groupName">
                <InputText
                  id="groupName"
                  name="groupName"
                  label="groupName"
                  hideLabel
                  onChangeHandler={(text: string) =>
                    setFieldValue('groupName', text)
                  }
                  invalidText={errors.groupName}
                  invalid={!!errors.groupName}
                  value={values.groupName}
                  disabled={managedByADSync}
                />
              </Label>
              <Label
                label="label_belongsTo"
                helperLabel="label_belongsTo_helper_text"
              >
                {!!!allGroups ? (
                  <InlineNotification
                    type="error"
                    titleLabel={intl.formatMessage({
                      id: 'component.notification.error.title',
                    })}
                    subtitleLabel={intl.formatMessage({
                      id: 'page.groupManagement.settings.dropdown.error.notification.subtitle',
                    })}
                    hideCloseButton={true}
                  />
                ) : (
                  <DropdownContainer>
                    <GroupsDropdown
                      withClientAsRoot
                      id="belongsTo"
                      onChange={({ selectedItem }) => {
                        setFieldValue(
                          'belongsTo',
                          selectedItem ? selectedItem.id : undefined,
                        );
                      }}
                      hideLabel
                      selectedGroup={values.belongsTo}
                      disabled={managedByADSync}
                      invalid={!!errors.belongsTo}
                      invalidText={
                        errors.belongsTo ? (
                          <Translation id={errors.belongsTo as string} />
                        ) : null
                      }
                    />
                  </DropdownContainer>
                )}
              </Label>
              {deleteCategoryError?.status === 400 && (
                <InlineNotification
                  type="error"
                  titleLabel={intl.formatMessage({
                    id: 'component.notification.error.title',
                  })}
                  subtitleLabel={intl.formatMessage({
                    id: 'page.groupManagement.settings.deleteGroup.error.notification.subtitle',
                  })}
                />
              )}
              <ButtonContainer>
                <Button
                  buttonType="danger"
                  label="label_deleteGroup"
                  onClick={() => {
                    handleDeleteGroup();
                  }}
                  disabled={managedByADSync}
                />
                <Button
                  type="submit"
                  label="label_save"
                  loading={isSubmitting}
                  disabled={!dirty || isSubmitting}
                />
              </ButtonContainer>
            </FlexBox>
          </Form>
        )}
      </Formik>
    </Wrapper>
  );
};

export default SettingsTab;
