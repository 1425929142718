import styled, { css } from 'styled-components';

import { TextProps } from './Text';

export type Level =
  | 'xs'
  | 'base'
  | 'md'
  | 'lg'
  | 'xl'
  | 'xxl'
  | 'xxxl'
  | 'xxxxl';

const sizeMap: Record<Level, string> = {
  xs: '0.875rem',
  base: '1rem',
  md: '1.25rem',
  lg: '1.5rem',
  xl: '1.75rem',
  xxl: '2rem',
  xxxl: '2.25rem',
  xxxxl: '2.5rem',
};

export const getResponsiveTypography = ({
  fontSizeLevel,
  lineHeightLevel,
}: {
  fontSizeLevel: Level;
  lineHeightLevel: Level;
}) => {
  const fontSize = sizeMap[fontSizeLevel];
  const lineHeightSize = sizeMap[lineHeightLevel];
  return { fontSize, lineHeightSize };
};

export type HeadingElement = 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6';
export type TextElement = 'span' | 'div' | 'p' | 'strong' | 'b' | 'em' | 'i';

export type CommonTextProps = {
  $fontSizeLevel?: Level;
  $lineHeightLevel?: Level;
  $textLetterSpacing?: string;
  $bold?: boolean;
  $color?: string;
  $cursor?: 'pointer' | 'default';
};

export type StyledTextProps = Omit<TextProps, 'bold'> & CommonTextProps;

export const StyledText = styled.div<StyledTextProps>`
  ${({ $textLetterSpacing }) =>
    $textLetterSpacing &&
    css`
      letter-spacing: ${$textLetterSpacing};
    `}

  ${({ $bold }) =>
    $bold &&
    css`
      font-weight: 600;
    `}

  ${(props) => {
    const { fontSize, lineHeightSize } = getResponsiveTypography({
      fontSizeLevel: props.$fontSizeLevel,
      lineHeightLevel: props.$lineHeightLevel,
    });
    return css`
      font-size: ${fontSize};
      line-height: ${lineHeightSize};
    `;
  }}

  ${({ $color }) =>
    $color &&
    css`
      color: ${$color};
    `}

  ${({ $cursor }) =>
    $cursor &&
    css`
      cursor: ${$cursor};
    `}
`;
