import React, { useMemo, useState } from 'react';

import { unstable_FeatureFlags as CarbonFeatureFlags } from '@carbon/react';
import Fuse from 'fuse.js';
import { IntlShape, useIntl } from 'react-intl';

import { useApiData } from 'api';
import { FlexBox } from 'components/Containers';
import Translation from 'components/Content/Translation/Translation';
import { FallbackErrorComponent } from 'components/Error';
import { Search as SearchInput } from 'components/Form/Inputs';
import { Dropdown } from 'components/Form/Inputs/Dropdowns';
import LoaderBlock from 'components/Spinner/LoaderBlock/LoaderBlock';
import Tag from 'components/Tag';
import { RadioTile, TileGroup } from 'components/Tile';
import { useAppSelector } from 'store/createStore';
import { selectIsCybSafeAdmin } from 'store/features/mainSlice';
import { unit } from 'theme';

import { DropdownContainer, TagWrapper } from '../OrganistationChooser.styles';

const getDemoOptions = (intl: IntlShape) => [
  {
    label: intl.formatMessage({
      id: 'common.all',
    }),
    id: 'unset',
  },
  {
    label: intl.formatMessage({
      id: 'orgChooser.filter.demo.demo',
    }),
    id: 'demo',
  },
  {
    label: intl.formatMessage({
      id: 'orgChooser.filter.demo.real',
    }),
    id: 'real',
  },
];

const getActiveOptions = (intl: IntlShape) => [
  {
    label: intl.formatMessage({
      id: 'common.all',
    }),
    id: 'unset',
  },
  {
    label: intl.formatMessage({
      id: 'orgChooser.filter.active.active',
    }),
    id: 'active',
  },
  {
    label: intl.formatMessage({
      id: 'orgChooser.filter.active.inactive',
    }),
    id: 'inactive',
  },
];

const fuseOptions = {
  threshold: 0.1,
  location: 0,
  distance: 100,
  maxPatternLength: 32,
  minMatchCharLength: 1,
  keys: ['clientName', 'id'],
};

const OrganisationChooserContent = ({ setSelectedOrgId }) => {
  const intl = useIntl();
  const isCybSafeAdmin = useAppSelector(selectIsCybSafeAdmin);

  const demoOptions = useMemo(() => getDemoOptions(intl), [intl]);
  const activeOptions = useMemo(() => getActiveOptions(intl), [intl]);

  const [filters, setFilters] = useState({
    search: '',
    activeFilter: activeOptions[0].id,
    demoFilter: demoOptions[0].id,
  });

  const { data, loading, error } = useApiData(
    '/api/v1/clients/r2/?page_size=3000',
    {
      cache: true,
    },
  );

  const items = useMemo(() => {
    if (data) {
      let filteredItems = [...data.results];
      if (filters.search) {
        const fuse = new Fuse(filteredItems, fuseOptions);
        const searchValues = fuse.search(filters.search);
        filteredItems =
          searchValues.length > 0
            ? searchValues.map((searchValue: any) => {
                const { item } = searchValue;
                return { ...item };
              })
            : [];
      }
      if (filters.activeFilter === 'active') {
        filteredItems = filteredItems.filter((item) => item.isActive);
      } else if (filters.activeFilter === 'inactive') {
        filteredItems = filteredItems.filter((item) => !item.isActive);
      }
      if (filters.demoFilter === 'demo') {
        filteredItems = filteredItems.filter((item) => item.isDummy);
      } else if (filters.demoFilter === 'real') {
        filteredItems = filteredItems.filter((item) => !item.isDummy);
      }
      return filteredItems;
    }
    return [];
  }, [data, filters.activeFilter, filters.demoFilter, filters.search]);

  if (loading) return <LoaderBlock />;
  if (error) return <FallbackErrorComponent error={error} />;

  return (
    <FlexBox
      data-testid="org-chooser-content"
      flexDirection="column"
      gap={unit(1)}
    >
      <SearchInput
        id="organisationChooserSearch-search-input"
        label={intl.formatMessage({
          id: 'label_organisationChooserSearch',
        })}
        placeholder={intl.formatMessage({
          id: 'label_organisationChooserSearch',
        })}
        onChangeHandler={(value) =>
          setFilters((prevState) => ({
            ...prevState,
            search: value,
          }))
        }
      />
      <FlexBox gap={unit(1)}>
        <DropdownContainer>
          <Dropdown
            id="is-active-dropdown"
            items={activeOptions}
            label={<Translation id="label_orgChooser_activeFilter" />}
            titleText={<Translation id="label_orgChooser_activeFilter" />}
            selectedItem={activeOptions.find(
              (option) => option.id === filters.activeFilter,
            )}
            onChange={({ selectedItem }) => {
              setFilters((prevState) => ({
                ...prevState,
                activeFilter: selectedItem.id as string,
              }));
            }}
          />
        </DropdownContainer>
        <DropdownContainer>
          <Dropdown
            id="is-demo-dropdown"
            items={demoOptions}
            label={<Translation id="label_orgChooser_demoFilter" />}
            titleText={<Translation id="label_orgChooser_demoFilter" />}
            selectedItem={demoOptions.find(
              (option) => option.id === filters.demoFilter,
            )}
            onChange={({ selectedItem }) => {
              setFilters((prevState) => ({
                ...prevState,
                demoFilter: selectedItem.id as string,
              }));
            }}
          />
        </DropdownContainer>
      </FlexBox>
      <div>
        {items.length > 0 ? (
          <TileGroup
            onChange={(value) => {
              setSelectedOrgId(value);
            }}
            name="organisationChooser"
          >
            {items.map((item) => (
              <CarbonFeatureFlags
                key={item.id}
                flags={{ 'enable-v12-tile-radio-icons': true }}
              >
                <RadioTile id={`${item.id}`} value={item.id}>
                  <FlexBox align="center" gap="16px">
                    <span>
                      {item.clientName}
                      {isCybSafeAdmin ? ` (${item.id})` : ''}
                    </span>
                    <TagWrapper>
                      {item.isDummy ? (
                        <Tag type="purple">
                          <Translation id="common.demo" />
                        </Tag>
                      ) : null}
                      {item.isActive ? (
                        <Tag type="green">
                          <Translation id="common.active" />
                        </Tag>
                      ) : (
                        <Tag type="red">
                          <Translation id="common.inactive" />
                        </Tag>
                      )}
                    </TagWrapper>
                  </FlexBox>
                </RadioTile>
              </CarbonFeatureFlags>
            ))}
          </TileGroup>
        ) : (
          <p>
            <Translation id="label_no_organisations_found" />
          </p>
        )}
      </div>
    </FlexBox>
  );
};

export default OrganisationChooserContent;
