import React from 'react';

import { faArrowUpRightFromSquare } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';

import Translation from 'components/Content/Translation/Translation';
import { SymposiumLevels } from 'store/features/mainSliceTypes';
import { convertToCamelCase } from 'utils';

import Guide from '../SubscriptionIcons/Guide';
import Phish from '../SubscriptionIcons/Phish';
import Respond from '../SubscriptionIcons/Respond';

import {
  CallOutContainer,
  DescriptionContainer,
  LinkContainer,
  TempIconPlaceholder,
} from './SubscriptionHeader.styles';

type DemoCalloutProps = {
  level: SymposiumLevels;
};

const getSubscriptionIcon = (level: SymposiumLevels) => {
  switch (level) {
    case 'RESPOND':
    case 'RESPOND+':
      return <Respond />;
    case 'GUIDE+':
    case 'GUIDE':
      return <Guide />;
    case 'PHISH':
    case 'PHISH+':
      return <Phish />;
    default:
      return <TempIconPlaceholder />;
  }
};

export const getSubscriptionLink = (level: SymposiumLevels) => {
  switch (level) {
    case 'RESPOND':
    case 'RESPOND+':
      return 'https://www.cybsafe.com/products/respond/';
    case 'GUIDE+':
    case 'GUIDE':
      return 'https://www.cybsafe.com/products/guide/';
    case 'PHISH':
    case 'PHISH+':
      return 'https://www.cybsafe.com/products/phish/';
    default:
      return 'https://www.cybsafe.com/products-overview/';
  }
};

const getSubscriptionLabelId = (level: SymposiumLevels) => {
  switch (level) {
    case 'GUIDE+':
    case 'PHISH+':
    case 'RESPOND+':
      // get product name without the + to get the correct label name
      const productLevel = level.substring(0, level.length - 1);
      return `common.subscription.callout.${convertToCamelCase(productLevel.toLocaleLowerCase())}.plus.description`;
    default:
      return `common.subscription.callout.${convertToCamelCase(level.toLocaleLowerCase())}.description`;
  }
};

const SubscriptionCallout = ({ level }: DemoCalloutProps) => {
  return (
    <CallOutContainer level={level}>
      <DescriptionContainer>
        <p>
          <Translation
            id={getSubscriptionLabelId(level)}
            values={{
              level: <strong>{level}</strong>,
              link: (
                <a href="mailto:support@cybsafe.com">support@cybsafe.com</a>
              ),
            }}
          />
        </p>
        <LinkContainer>
          <Link to={getSubscriptionLink(level)} target="_blank">
            <Translation
              id="common.subscription.callout.link.label"
              values={{
                level: <strong>{level}</strong>,
              }}
            />
          </Link>
          <FontAwesomeIcon icon={faArrowUpRightFromSquare} />
        </LinkContainer>
      </DescriptionContainer>
      <span>{getSubscriptionIcon(level)}</span>
    </CallOutContainer>
  );
};

export default SubscriptionCallout;
