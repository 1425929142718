import React from 'react';

import { StyledText, StyledTextProps, TextElement } from './Text.styles';

export interface TextProps extends React.HTMLAttributes<HTMLSpanElement> {
  children: any;
  as?: TextElement;
  variant?: 'body' | 'label' | 'bodySmall';
  bold?: boolean;
  className?: string;
  onClick?: (e?: React.MouseEvent<HTMLElement>) => void;
  cursor?: 'pointer' | 'default';
}

const Text = ({
  children,
  as = 'span',
  variant = 'body',
  bold,
  color,
  cursor,
  ...rest
}: TextProps) => {
  const config: Pick<StyledTextProps, '$fontSizeLevel' | '$lineHeightLevel'> = {
    $fontSizeLevel: 'base',
    $lineHeightLevel: 'base',
  };

  switch (variant) {
    case 'body':
      config.$fontSizeLevel = 'base';
      config.$lineHeightLevel = 'lg';
      break;
    case 'label':
      config.$fontSizeLevel = 'xs';
      config.$lineHeightLevel = 'xs';
      break;
    case 'bodySmall':
      config.$fontSizeLevel = 'xs';
      config.$lineHeightLevel = 'md';
      break;
  }

  return (
    <StyledText
      as={as}
      $bold={bold}
      $fontSizeLevel={config.$fontSizeLevel}
      $lineHeightLevel={config.$lineHeightLevel}
      $textLetterSpacing="0.01rem"
      $color={color}
      $cursor={cursor}
      {...rest}
    >
      {children}
    </StyledText>
  );
};

export default Text;
