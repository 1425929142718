import React, { useRef } from 'react';

import { useIntl } from 'react-intl';

import { PersonalisationItem } from 'components/Component.types';
import { Box, FlexBox } from 'components/Containers';
import { isLabel } from 'utils';

import { InputWrapper } from '../Inputs.styles';
import PersonaliseBarItem from '../Personalisation/BarItem';

import { Input } from './TextInput.styles';

export interface TextInputProps
  extends Pick<React.InputHTMLAttributes<HTMLInputElement>, 'min' | 'max'> {
  id?: string;
  label?: string;
  newLabel?: string;
  onChangeHandler?: (arg0: string) => void;
  className?: string;
  name?: string;
  type?: string;
  placeholder?: string;
  hideLabel?: boolean;
  invalidLabel?: string;
  invalidText?: string;
  invalid?: boolean;
  value?: string | number;
  disabled?: boolean;
  onKeyDown?: (event: React.KeyboardEvent) => void;
  personalisationItems?: PersonalisationItem[];
  showPersonalisationIcon?: boolean;
}

export const InputText = ({
  id,
  label,
  newLabel,
  onChangeHandler,
  className,
  name,
  type,
  placeholder,
  hideLabel,
  invalidLabel,
  invalidText,
  invalid,
  value,
  disabled,
  onKeyDown,
  personalisationItems,
  showPersonalisationIcon = true,
  ...rest
}: TextInputProps) => {
  const intl = useIntl();
  const inputRef = useRef<HTMLInputElement>();

  return (
    <InputWrapper>
      <FlexBox>
        <Input
          ref={inputRef}
          id={id ? `${id}-text-input` : `${label}-text-input`}
          className={className}
          name={name}
          labelText={
            label || newLabel
              ? intl.formatMessage({
                  id: newLabel ? newLabel : `label_${label}`,
                })
              : ''
          }
          hideLabel={hideLabel}
          type={type}
          placeholder={
            placeholder && isLabel(placeholder)
              ? intl.formatMessage({
                  id: placeholder,
                })
              : placeholder
          }
          invalidText={
            invalidText
              ? invalidText
              : intl.formatMessage({
                  id: `label_${invalidLabel}`,
                })
          }
          invalid={invalid}
          onChange={({ target }) => onChangeHandler(target.value)}
          onKeyDown={onKeyDown}
          value={value}
          disabled={disabled}
          {...rest}
        />
        {personalisationItems && personalisationItems.length > 0 && (
          <Box aligns="flex-start" t="6px" pos="relative">
            <PersonaliseBarItem
              showPersonalisationIcon={showPersonalisationIcon}
              personalisationItems={personalisationItems}
              onPersonalisationSelected={(option: string) => {
                // insert the selected personalisation option at the current cursor position
                if (inputRef) {
                  const input = inputRef.current;
                  if (!input) {
                    return;
                  }
                  const cursorPosition = input.selectionStart;
                  const textBeforeCursorPosition = input.value.substring(
                    0,
                    cursorPosition,
                  );
                  const textAfterCursorPosition = input.value.substring(
                    cursorPosition,
                    input.value.length,
                  );
                  const newValue = `${textBeforeCursorPosition}${option}${textAfterCursorPosition}`;
                  input.value = newValue;
                  onChangeHandler(newValue);
                }
              }}
            />
          </Box>
        )}
      </FlexBox>
    </InputWrapper>
  );
};

export default InputText;
