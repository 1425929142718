import React, { ComponentType, ErrorInfo, PureComponent } from 'react';

import * as Sentry from '@sentry/browser';
import { useIntl } from 'react-intl';

import Button from 'components/Button';
import Translation from 'components/Content/Translation/Translation';
import { Wrapper } from 'components/Wrappers';

import { ErrorContainer, MobileErrorContainer } from './Error.styles';

type State = {
  error: Error | null | undefined;
};

export type APIError = {
  status: number;
  response: {
    requestId?: string;
  };
};

export type FallbackErrorComponentProps = {
  error?: APIError;
  title?: string;
  withRetryButton?: boolean;
  retryButtonCallback?: () => void;
  removeBorder?: boolean;
};

const ERROR_STATUS_LABELS = {
  400: 'common.error.badRequest',
  401: 'common.error.unauthorized',
  403: 'common.error.forbidden',
  404: 'common.error.notFound',
  500: 'common.error.internalServerError',
};

const getErrorHeading = (title: string, error: APIError) => {
  if (title) return title;
  if (error?.status && ERROR_STATUS_LABELS[error.status])
    return ERROR_STATUS_LABELS[error.status];
  return 'label_someThingWentWrong';
};

export const FallbackErrorComponent = ({
  error,
  title,
  withRetryButton,
  retryButtonCallback,
  removeBorder,
}: FallbackErrorComponentProps) => {
  const intl = useIntl();
  return (
    <Wrapper testId="error-section" removeBorder={removeBorder}>
      <ErrorContainer>
        <h4>
          {intl.formatMessage({
            id: getErrorHeading(title, error),
            defaultMessage: 'Something went wrong',
          })}
        </h4>
        <img
          src="https://s3-eu-west-1.amazonaws.com/cybsafe-resources/robot-not-found.png"
          alt=""
        />
        {error && (
          <>
            {error.status || error.response?.requestId ? (
              <>
                {error.status && (
                  <p>
                    <span>{`Status: ${error.status}`}</span>
                  </p>
                )}
                {error.response?.requestId && (
                  <p>
                    <span>{`Request Id: ${error.response.requestId}`}</span>
                  </p>
                )}
              </>
            ) : (
              <Button
                href={`${window.location.origin}/`}
                label="component.error.button.backHome"
              />
            )}
          </>
        )}
        {withRetryButton && (
          <Button
            onClick={retryButtonCallback}
            data-testid="error-retry-button"
            label="common.button.retry"
          />
        )}
      </ErrorContainer>
    </Wrapper>
  );
};

export const FallbackErrorMobileComponent = () => (
  <MobileErrorContainer>
    <h4>
      <Translation id="label_someThingWentWrong" />
    </h4>
    <img
      src="https://s3-eu-west-1.amazonaws.com/cybsafe-resources/robot-not-found.png"
      alt=""
    />
  </MobileErrorContainer>
);

// Error used when the bootstrap call fails, which means the core lang is not called so no formatted messages used
export const FallbackErrorNoLanguage = () => (
  <ErrorContainer>
    <h4>Oops! That didn&apos;t work for some reason.</h4>
    <img
      src="https://s3-eu-west-1.amazonaws.com/cybsafe-resources/robot-not-found.png"
      alt=""
    />
  </ErrorContainer>
);

type ErrorBoundaryProps = {
  children?: React.ReactNode;
  onError?: (error: Error, componentStack: string) => void;
};

export class ErrorBoundary extends PureComponent<ErrorBoundaryProps, State> {
  static defaultProps = {
    FallbackComponent: FallbackErrorComponent,
  };
  state = {
    error: null,
  };

  componentDidCatch(error: Error, info: ErrorInfo): void {
    const { onError } = this.props;

    if (typeof onError === 'function') {
      try {
        /* istanbul ignore next: Ignoring ternary; can’t reproduce missing info in test environment. */
        onError.call(this, error, info ? info.componentStack : '');
      } catch (ignoredError) {
        // eslint-disable-next-line no-console
        console.log(ignoredError);
      }
    }

    Sentry.captureException(error);
    this.setState({
      error,
    });
  }

  render() {
    const { children } = this.props;
    const { error } = this.state;

    if (error !== null) {
      return <FallbackErrorComponent error={error} />;
    }

    return children || null;
  }
}

export const withErrorBoundary = <P extends object>(
  Component: ComponentType<P>,
): ComponentType<P> => {
  const Wrapped: React.FC<P> = (props) => (
    <ErrorBoundary>
      <Component {...props} />
    </ErrorBoundary>
  );

  const name = Component.displayName || Component.name;
  Wrapped.displayName = name
    ? `WithErrorBoundary(${name})`
    : 'WithErrorBoundary';

  return Wrapped;
};
