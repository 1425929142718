import { DefaultTheme } from 'styled-components';

import { coreTheme } from '../coreTheme';

const { colorPalette, fontSizes, headings } = coreTheme;

export const getLightComponentColors = (
  colorPalette: typeof coreTheme.colorPalette,
) => ({
  bodyTextColor: colorPalette.grey[800],
  bodyTextLinkColor: colorPalette.primary[300],
  moreLinkColor: colorPalette.grey[800],
  helpTextColor: colorPalette.grey[700],

  headerBg: colorPalette.core.white,
  bodyBg: colorPalette.core.white,
  pageTitleColor: colorPalette.grey[800],
  pageSubTitleColor: colorPalette.grey[800],
  headerLinkColor: colorPalette.core.black,

  modalContentBg: colorPalette.core.white,
  modalOverlayBg: 'rgb(22 22 22 / 50%)',

  wrapperBg: colorPalette.core.white,

  accordion: {
    titleBorderColor: colorPalette.grey[200],
    normal: {
      titleSection: {
        backgroundColor: colorPalette.grey[100],
        textColor: colorPalette.grey[800],
        borderColor: colorPalette.primary[300],
      },
    },
    light: {
      titleSection: {
        backgroundColor: colorPalette.core.white,
        textColor: colorPalette.grey[800],
      },
    },
  },

  header: {
    linkColor: colorPalette.grey[800],
    linkHoverColor: colorPalette.core.black,
    linkBackgroundColor: colorPalette.grey[100],
    userMenuIconFill: colorPalette.grey[900],
    userMenuIconFillHover: colorPalette.grey[200],
    userMenuIconFillBorderFocus: colorPalette.primary[300],
  },

  card: {
    backgroundColor: colorPalette.core.white,
    alternateBackgroundColor: colorPalette.grey[50],
    borderColor: colorPalette.grey[200],
  },

  sideNav: {
    subMenuTextColor: colorPalette.core.white,
    backgroundColor: colorPalette.grey[800],
    borderColor: 'transparent',
  },

  filterButtons: {
    backgroundColor: colorPalette.core.white,
    borderColor: colorPalette.grey[500],
  },

  inputField: {
    backgroundColor: '#ebf0f7',
    borderColor: colorPalette.grey[300],
    textColor: colorPalette.grey[800],
    arrowColor: colorPalette.primary[300],
    dropdownBackgroundColor: colorPalette.core.white,
    dropdownHoverBackgroundColor: colorPalette.grey[100],
    radioCircleColor: colorPalette.core.black,
    radioCircleSelectedColor: colorPalette.primary[300],
    dropdownUnderline: colorPalette.grey[300],
  },

  inlineNotification: {
    backgroundColor: colorPalette.core.white,
  },

  home: {
    toDoCultureBackground: colorPalette.ui.purple[50],
    toDoRefresherBackground: colorPalette.ui.pink[50],
    toDoGoalBackground: colorPalette.primary[100],
    toDoModuleBackground: colorPalette.ui.orange[50],
    toDoUserCharacteristicsBackground: colorPalette.ui.coral[50],
    goalsOverviewLink: colorPalette.primary[300],
    viewAllActionsLink: colorPalette.secondary[500],
  },

  tabs: {
    borderBottomColor: colorPalette.core.whiteSmoke,
    tabTitleColor: colorPalette.grey[700],
    tabTitleHoverColor: colorPalette.grey[900],
    tabTitleSelectedColor: colorPalette.grey[900],
    borderBottomColorActive: colorPalette.primary[300],
  },

  login: {
    topBorderColor: colorPalette.grey[100],
    orLoginBackgroundColor: colorPalette.core.white,
  },

  blocks: {
    fullWidthImageWithCaptionText: colorPalette.grey[900],
    tableEvenRowBg: colorPalette.grey[50],
  },

  breadcrumbs: {
    activeLinkColor: colorPalette.primary[500],
    linkColor: colorPalette.grey[800],
    iconColor: colorPalette.grey[500],
  },
  charts: {
    securityBehaviourColor: colorPalette.primary[500],
    riskScoreColor: colorPalette.ui.pink[900],
    securityBehaviourIndustryColor: colorPalette.primary[300],
    riskScoreIndustryColor: colorPalette.ui.pink[300],
    industryComparisonColor: colorPalette.grey[600],
    radarGridColor: colorPalette.grey[100],
    noDataColor: colorPalette.core.white,
    accountCompromiseColor: colorPalette.ui.pink[500],
    dataLeakColor: colorPalette.ui.purple[600],
    dataTheftColor: colorPalette.ui.blue[600],
    identityTheftColor: colorPalette.ui.orange[500],
    privacyViolationColor: colorPalette.ui.blue[400],
    physicalDamageColor: colorPalette.primary[500],
    personalExposureColor: colorPalette.primary[300],
    malwareInfectionColor: colorPalette.ui.coral[500],
  },
  table: {
    hover: {
      backgroundColor: colorPalette.grey[100],
    },
    selected: {
      backgroundColor: colorPalette.grey[100],
    },
    header: {
      backgroundColor: colorPalette.grey[100],
      textColor: colorPalette.grey[800],
      headerOutline: colorPalette.primary[300],
    },
    cells: {
      borderColor: colorPalette.core.whiteSmoke,
    },
    search: {
      hoverColor: colorPalette.grey[100],
    },
    batchActions: {
      backgroundColor: colorPalette.core.white,
      borderColor: colorPalette.grey[100],
    },
  },
  banner: {
    primaryBehaviourBanner: {
      textColor: colorPalette.grey[800],
      iconColor: colorPalette.primary[200],
      backgroundColor: colorPalette.primary[100],
    },
  },

  graphs: {
    primaryTone: colorPalette.tones.positive,
    warningTone: colorPalette.tones.warning,
  },

  tooltip: {
    backgroundColor: colorPalette.grey[800],
    textColor: colorPalette.core.white,
    linkColor: colorPalette.primary[200],
    linkHoverColor: colorPalette.primary[100],
  },
  footer: {
    linkColor: colorPalette.primary[400],
  },
  icons: {
    outline: colorPalette.primary[300],
  },
});

const defaultTheme: DefaultTheme = {
  name: 'light',
  fontSizes,
  headings,
  colorPalette,
  componentColors: getLightComponentColors(colorPalette),
};

export default defaultTheme;
