export const initialState = { display: false };

export const ACTIONS = {
  SHOW: 'SHOW',
  HIDE: 'HIDE',
};

export const reducer = (state, action) => {
  switch (action.type) {
    case ACTIONS.SHOW:
      return { display: true };
    case ACTIONS.HIDE:
      return { display: false };
    default:
      return { display: false };
  }
};
