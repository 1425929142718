import styled from 'styled-components';

import { breakpoint, unit } from 'theme';

export const NumberValue = styled.div`
  display: flex;
  align-items: center;
  gap: ${unit(1)};
  margin-bottom: ${unit(1)};
  > span,
  > a {
    color: ${({ theme }) => theme.componentColors.bodyTextColor};
    font-size: ${({ theme }) => theme.headings.l};
    font-weight: 600;
  }
  svg {
    color: var(--cds-icon-secondary, #525252);
  }
`;

export const IconContainer = styled.div`
  ${breakpoint('tablet')} {
    display: none;
  }
`;

export const NumberValueContainer = styled.div`
  max-width: 220px;
`;

export const NumberWithIconContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
`;
