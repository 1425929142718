import React from 'react';

import { Events } from '@carbon/icons-react';
import { useIntl } from 'react-intl';
import { Link } from 'react-router-dom';

import { Box } from 'components/Containers';
import Translation from 'components/Content/Translation/Translation';
import { NumberWithIcon } from 'components/Reports';
import { Heading } from 'components/Text';
import {
  GroupNameContainer,
  GroupOverviewContainer,
  GroupTypeOverviewTitle,
  ManagedByADSyncOrSSO,
  RootGroupViewMore,
  StatsContainer,
} from 'pages/Settings/GroupManagement/components/GroupManagement.styles';

import { GroupsTypeConfig } from '../helpers';

import { tabIDs } from './ManageGroupTabs';

type GroupOverviewProps = {
  groupName: string;
  usersInGroup: any;
  managedByADSync?: boolean;
  isRoot?: boolean;
  groupType?: string;
};

export enum TabOrder {
  People = 'people',
  SubGroups = 'sub-groups',
  Modules = 'modules',
  Settings = 'settings',
}

const GroupOverview = ({
  groupName,
  usersInGroup,
  managedByADSync,
  isRoot,
  groupType,
}: GroupOverviewProps) => {
  const intl = useIntl();

  return (
    <GroupOverviewContainer>
      <GroupNameContainer>
        {groupType && (
          <GroupTypeOverviewTitle>
            <Translation
              id="page.groupManagement.manage.overview.group.type"
              values={{
                groupType: intl.formatMessage({
                  id: GroupsTypeConfig[groupType].text,
                }),
              }}
            />
          </GroupTypeOverviewTitle>
        )}
        <Box mb="1rem">
          <Heading as="h1">{groupName}</Heading>
        </Box>
        {isRoot && (
          <RootGroupViewMore data-testid="root-view-more-link">
            <Link
              to={`/settings/group-management/root?selected=${tabIDs.modules}`}
            >
              <Translation id="label_viewGroup" />
            </Link>
          </RootGroupViewMore>
        )}
        {managedByADSync && (
          <ManagedByADSyncOrSSO translation="page.groupManagement.manage.overview.sync.description" />
        )}
        {groupType && groupType === 'sso' && (
          <ManagedByADSyncOrSSO translation="page.groupManagement.manage.overview.sso.description" />
        )}
      </GroupNameContainer>
      <StatsContainer>
        <NumberWithIcon
          value={usersInGroup}
          label="label_peopleInGroup"
          renderIcon={<Events />}
        />
      </StatsContainer>
    </GroupOverviewContainer>
  );
};

export default GroupOverview;
