import React, { ReactNode, useEffect } from 'react';

import { useIntl } from 'react-intl';
import { Navigate, useLocation, useSearchParams } from 'react-router-dom';

import { useApiData } from 'api';
import { Box } from 'components/Containers';
import { getCultureSurveySlug } from 'pages/CultureSurvey/utils';
import Logout from 'routes/components/Logout';
import { useOriginRouteContext } from 'routes/OriginRouteContext';
import { ACTIONS } from 'routes/reducer';
import { useAppDispatch, useAppSelector } from 'store/createStore';
import {
  selectIsCybSafeAdmin,
  selectUserInfo,
  setDelegateAccessClientId,
} from 'store/features/mainSlice';
import { getCookie } from 'utils/token';

type RouteWithContextProps = {
  title?: string;
  children: ReactNode;
};

const iaRoutes = [
  '/questionnaire',
  '/survey',
  '/survey-debrief',
  '/quiz/ia-knowledge-questions',
];

const currentPathIsNotIaRoute = () =>
  iaRoutes.filter((iaRoute) => window.location.pathname.indexOf(iaRoute) === 0)
    .length === 0;

const shouldRedirectToIA = (
  initialAssessmentRequired,
  cultureRequired,
  cultureMandatory,
  skipIaAndCulture,
  eulaRequired,
) =>
  (initialAssessmentRequired || (cultureRequired && cultureMandatory)) && // ia or culture survey completion required
  currentPathIsNotIaRoute() && // not already on the ia page
  !skipIaAndCulture && // user has not already skipped the ia or culture
  !eulaRequired; // eula has been completed

const shouldRedirectToEula = (
  hasCookie,
  eulaRequired,
  delegatedAccessClientId,
) =>
  hasCookie && // authenticated
  eulaRequired && // eula not already signed
  delegatedAccessClientId === null && // not in delegated access
  window.location.pathname !== '/eula'; // not already on the eula page

const shouldHandleDelegatedAccess = (
  delegatedAccessClientIdParams,
  isCybSafeAdmin,
  delegatedAccessClientId,
) =>
  delegatedAccessClientIdParams && // has delegated access client id in the url
  isCybSafeAdmin &&
  delegatedAccessClientIdParams !== delegatedAccessClientId; // if not already delegated to the same client

const shouldRedirectToStandaloneCulture = (userInfo, settings) =>
  !userInfo.isAdmin && // is not admin
  settings.data &&
  settings.data.cultureAssessmentStandalone && // standalone culture assessment setting is on
  typeof window.location.pathname === 'string' &&
  !window.location.pathname.startsWith('/survey'); // not already on the culture survey pages

const RouteWithContext = ({ title, children }: RouteWithContextProps) => {
  const intl = useIntl();
  const location = useLocation();
  const [params] = useSearchParams();
  const delegatedAccessClientIdParams = params.get(
    'delegated_access_client_id',
  );
  const dispatch = useAppDispatch();
  const { dispatch: dispatchOriginRoute } = useOriginRouteContext();
  const userInfo = useAppSelector(selectUserInfo);
  const {
    eulaRequired,
    delegatedAccessClientId,
    cultureRequired,
    cultureMandatory,
    initialAssessmentRequired,
    skipIaAndCulture,
  } = useAppSelector((state) => state.main);
  const isCybSafeAdmin = useAppSelector(selectIsCybSafeAdmin);

  const hasCookie = getCookie() || false;
  const delegatedAccessHandler = async (client) => {
    await dispatch(setDelegateAccessClientId(client));
    dispatchOriginRoute(ACTIONS.CLEAR);
    window.location.reload();
  };

  const settings = useApiData('/api/v1/cybsafe-settings/r1/active-settings/', {
    cache: true,
  });

  useEffect(() => {
    document.title = title
      ? `${intl.formatMessage({
          id: title,
        })} | CybSafe`
      : 'CybSafe';
  }, [title, intl]);

  if (
    shouldHandleDelegatedAccess(
      delegatedAccessClientIdParams,
      isCybSafeAdmin,
      delegatedAccessClientId,
    )
  ) {
    delegatedAccessHandler(delegatedAccessClientIdParams);
  }
  if (shouldRedirectToEula(hasCookie, eulaRequired, delegatedAccessClientId)) {
    return <Navigate to="/eula" />;
  }
  if (shouldRedirectToStandaloneCulture(userInfo, settings)) {
    return (
      <Navigate
        to={`/survey/${getCultureSurveySlug(
          settings.data?.cultureSurveyVersion2Enabled,
        )}?culture=ca-standalone`}
      />
    );
  }
  if (
    shouldRedirectToIA(
      initialAssessmentRequired,
      cultureRequired,
      cultureMandatory,
      skipIaAndCulture,
      eulaRequired,
    )
  ) {
    return <Navigate to="/questionnaire" />;
  }

  if (!hasCookie) {
    return <Logout fullPath={location.pathname + location.search} />;
  }

  return (
    <Box flex="1 1 auto" id="children-container">
      {children}
    </Box>
  );
};

export default RouteWithContext;
