import { createSlice } from '@reduxjs/toolkit';

export interface SettingsStateInterface {
  cultureAssessmentStandalone: boolean | null;
  cultureSurveyVersion2Enabled: boolean | null;
  departmentSeparator: boolean | null;
  editableLabelsEnabled: boolean | null;
  enableNotifications: boolean | null;
  menuVersion: number;
  userUiTheme: 'light' | 'dark';
  enableSecurityHeroesWidget: boolean | null;
  userCharacteristicsSurvey: boolean | null;
  setEulaButtonCopyToOk: boolean | null;
  ssoBackstop: boolean | null;
  enablePassphraseLogin: boolean | null;
  visualEditorDisabled: boolean | null;
}

const initialState: SettingsStateInterface = {
  cultureAssessmentStandalone: null,
  cultureSurveyVersion2Enabled: null,
  departmentSeparator: null,
  editableLabelsEnabled: null,
  enableNotifications: null,
  enableSecurityHeroesWidget: null,
  menuVersion: 2,
  userUiTheme: 'light',
  userCharacteristicsSurvey: null,
  setEulaButtonCopyToOk: null,
  ssoBackstop: null,
  enablePassphraseLogin: null,
  visualEditorDisabled: null,
};

export const settingsSlice = createSlice({
  name: 'userSettings',
  initialState,
  reducers: {
    setSettings: (state, action) => {
      return action.payload;
    },
  },
});

/* Actions */
export const { setSettings } = settingsSlice.actions;

/* Selectors */
export const selectSettings = (state) => state.userSettings;

export default settingsSlice.reducer;
