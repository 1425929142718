import { DefaultTheme } from 'styled-components';

import { coreTheme } from '../coreTheme';

const { fontSizes, headings } = coreTheme;

const colorPalette = { ...coreTheme.colorPalette };

colorPalette.primary = {
  100: '#92D3CB',
  200: '#618c8c',
  300: '#1D5A5B',
  400: '#1D5A5B',
  500: '#1D5A5B',
};

export const getDarkComponentColors = (
  colorPalette: typeof coreTheme.colorPalette,
) => ({
  bodyTextColor: colorPalette.grey[300],
  bodyTextLinkColor: colorPalette.primary[100],
  moreLinkColor: colorPalette.grey[200],
  helpTextColor: colorPalette.grey[400],
  bodyBg: '#2d2d2d',
  headerBg: '#202020',
  pageTitleColor: '#e3e3e3',
  pageSubTitleColor: '#d7d7d7',

  modalContentBg: '#4a4a4a',
  modalOverlayBg: 'rgb(22 22 22 / 80%)',

  wrapperBg: '#444444',

  accordion: {
    titleBorderColor: colorPalette.grey[600],
    normal: {
      titleSection: {
        backgroundColor: colorPalette.grey[800],
        textColor: colorPalette.grey[100],
        borderColor: colorPalette.primary[300],
      },
    },
    light: {
      titleSection: {
        backgroundColor: colorPalette.grey[400],
        textColor: colorPalette.grey[900],
      },
    },
  },

  header: {
    linkColor: colorPalette.grey[300],
    linkHoverColor: colorPalette.grey[500],
    linkBackgroundColor: colorPalette.grey[800],
    userMenuIconFill: colorPalette.core.white,
    userMenuIconFillHover: 'transparent',
    userMenuIconFillBorderFocus: colorPalette.primary[400],
  },

  card: {
    backgroundColor: colorPalette.grey[800],
    alternateBackgroundColor: colorPalette.grey[700],
    borderColor: colorPalette.grey[700],
  },

  sideNav: {
    subMenuTextColor: colorPalette.grey[300],
    backgroundColor: '#2d2d2d',
    borderColor: colorPalette.grey[700],
  },

  filterButtons: {
    backgroundColor: colorPalette.grey[800],
    borderColor: colorPalette.grey[700],
  },

  inputField: {
    backgroundColor: colorPalette.grey[700],
    borderColor: colorPalette.grey[700],
    textColor: colorPalette.grey[100],
    arrowColor: colorPalette.grey[100],
    dropdownBackgroundColor: colorPalette.grey[800],
    dropdownHoverBackgroundColor: colorPalette.grey[700],
    radioCircleColor: colorPalette.core.whiteSmoke,
    radioCircleSelectedColor: colorPalette.primary[100],
    dropdownUnderline: colorPalette.grey[900],
  },

  inlineNotification: {
    backgroundColor: colorPalette.grey[700],
  },

  home: {
    toDoCultureBackground: colorPalette.ui.purple[200],
    toDoRefresherBackground: colorPalette.ui.pink[200],
    toDoGoalBackground: colorPalette.primary[100],
    toDoModuleBackground: colorPalette.ui.orange[200],
    toDoUserCharacteristicsBackground: colorPalette.ui.coral[200],
    goalsOverviewLink: colorPalette.grey[300],
    viewAllActionsLink: colorPalette.grey[300],
  },

  tabs: {
    borderBottomColor: 'transparent',
    tabTitleColor: colorPalette.grey[600],
    tabTitleHoverColor: colorPalette.grey[200],
    tabTitleSelectedColor: colorPalette.grey[400],
    borderBottomColorActive: colorPalette.primary[400],
  },

  login: {
    topBorderColor: 'transparent',
    orLoginBackgroundColor: '#2d2d2d',
  },

  blocks: {
    fullWidthImageWithCaptionText: colorPalette.grey[400],
    tableEvenRowBg: colorPalette.grey[700],
  },

  breadcrumbs: {
    activeLinkColor: colorPalette.grey[100],
    linkColor: colorPalette.core.ghostWhite,
    iconColor: colorPalette.grey[100],
  },
  charts: {
    securityBehaviourColor: colorPalette.primary[300],
    riskScoreColor: colorPalette.ui.pink[500],
    securityBehaviourIndustryColor: colorPalette.primary[100],
    riskScoreIndustryColor: colorPalette.ui.pink[300],
    industryComparisonColor: colorPalette.grey[400],
    radarGridColor: colorPalette.grey[700],
    noDataColor: colorPalette.grey[800],
    accountCompromiseColor: colorPalette.ui.pink[500],
    dataLeakColor: colorPalette.ui.purple[600],
    dataTheftColor: colorPalette.ui.blue[600],
    identityTheftColor: colorPalette.ui.orange[500],
    privacyViolationColor: colorPalette.ui.blue[400],
    physicalDamageColor: colorPalette.primary[500],
    personalExposureColor: colorPalette.primary[300],
    malwareInfectionColor: colorPalette.ui.coral[500],
  },
  table: {
    hover: {
      backgroundColor: colorPalette.grey[700],
    },
    selected: {
      backgroundColor: colorPalette.grey[800],
    },
    header: {
      backgroundColor: colorPalette.grey[800],
      textColor: colorPalette.grey[100],
      headerOutline: colorPalette.primary[300],
    },
    cells: {
      borderColor: colorPalette.grey[900],
    },
    search: {
      hoverColor: colorPalette.grey[800],
    },
    batchActions: {
      backgroundColor: colorPalette.grey[700],
      borderColor: colorPalette.grey[800],
    },
  },
  banner: {
    primaryBehaviourBanner: {
      textColor: colorPalette.grey[800],
      iconColor: colorPalette.primary[500],
      backgroundColor: '#E8F2F2',
    },
  },
  graphs: {
    primaryTone: colorPalette.tones.positive,
    warningTone: colorPalette.tones.warning,
  },
  tooltip: {
    backgroundColor: colorPalette.grey[800],
    textColor: colorPalette.grey[100],
    linkColor: colorPalette.primary[300],
    linkHoverColor: colorPalette.primary[400],
  },
  footer: {
    linkColor: colorPalette.primary[100],
  },
  icons: {
    outline: colorPalette.primary[200],
  },
});

const darkTheme: DefaultTheme = {
  name: 'dark',
  fontSizes,
  headings,
  colorPalette,
  componentColors: getDarkComponentColors(colorPalette),
};

export default darkTheme;
