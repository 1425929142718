import React from 'react';

import { Link } from 'react-router-dom';

import Translation from 'components/Content/Translation/Translation';
import { Heading, Text } from 'components/Text';

import { LearningCampaignsRedirect } from './GroupManagement.styles';

const ModulesTab = () => {
  return (
    <LearningCampaignsRedirect>
      <Heading as="h2" variant="h2">
        <Translation id="page.groupManagement.modules.learningCampaigns.heading" />
      </Heading>
      <Text as="p">
        <Translation id="page.groupManagement.modules.learningCampaigns.message" />
      </Text>
      <Text as="p">
        <Link to="/admin/learning-campaigns">
          <Translation id="page.groupManagement.modules.learningCampaigns.link" />
          {` >`}
        </Link>
      </Text>
    </LearningCampaignsRedirect>
  );
};

export default ModulesTab;
